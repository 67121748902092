<template>
  <div>
    <div id="container" :style="'height:' + pmHeight + 'px;'"></div>
    <div class="search-box" v-if="this.tripInfo != null">
      <div class="list-item">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div>
            <div style="font-size: 16px; font-weight: 600">
              {{ tripInfo.islong == 1 ? time2(tripInfo.tripDate) : '每天'}} {{ tripInfo.tripTime }}
            </div>
            <div v-if="userTypes == '2'">
              <span style="margin-right: 5px"
                ><van-tag type="primary"
                  >{{ tripInfo.passengerNum }}人</van-tag
                ></span
              >
              <span style="margin-right: 5px"
                ><van-tag type="success">{{
                  tripInfo.carpooling == 1 ? "愿拼座" : "不拼座"
                }}</van-tag></span
              >
              <span style="margin-right: 5px"
                ><van-tag type="danger">{{
                  tripInfo.luggage == 1 ? "无大件行李" : "有大件行李"
                }}</van-tag></span
              >
              <span><van-tag type="warning">{{ tripInfo.islong == 1 ? '单次行程' : '长期行程' }}</van-tag></span>
            </div>
            <div v-else>
              <span style="margin-right: 5px"
                ><van-tag type="primary"
                  >可坐{{ tripInfo.passengerNum }}人</van-tag
                ></span
              >
              <span style="margin-right: 5px"
                ><van-tag type="success">{{
                  formatCarNo(tripInfo.users.car_no)
                }}</van-tag></span
              >
              <span><van-tag type="warning">{{ tripInfo.islong == 1 ? '单次行程' : '长期行程' }}</van-tag></span>
            </div>
          </div>
          <div style="font-size: 22px; font-weight: 500" v-if="tripInfo.fee!=null">
            {{ tripInfo.fee
            }}<span style="font-size: 12px; margin-left: 2px">元</span>
          </div>
          <div style="font-size: 18px; font-weight: 600" v-else>
            面议
          </div>
        </div>
        <div
          style="
            margin-top: 15px;
            padding-bottom: 10px;
            /* border-bottom: 1px solid #f2f2f2; */
          "
        >
          <div style="display: flex; font-size: 12px">
            <div>
              <van-icon
                name="location-o"
                style="font-size: 16px; margin-right: 3px"
              />起点：
            </div>
            <div>{{ tripInfo.startAddress }}</div>
          </div>
          <div
            style="
              width: 95%;
              margin-left: 5%;
              height: 1px;
              /* background: #f2f2f2; */
              margin-top: 5px;
            "
          ></div>
          <div style="display: flex; font-size: 12px; margin-top: 5px" v-if="tjdInfo!=null">
            <div>
              <van-icon
                name="location-o"
                style="font-size: 16px; margin-right: 3px"
              />途经：
            </div>
            <div style="display:flex;flex-direction: column;"><div  v-for="(item,index) in tjdInfo" :key="index">{{ item.name }}</div></div>
          </div>
          <div style="display: flex; font-size: 12px; margin-top: 5px">
            <div>
              <van-icon
                name="location-o"
                style="font-size: 16px; margin-right: 3px"
              />终点：
            </div>
            <div>{{ tripInfo.endAddress }} <van-tag type="primary" v-if="drevingInfo!=null"
                  >全程{{(drevingInfo.routes[0].distance / 1000).toFixed(1)}}公里</van-tag
                ></div>
          </div>
          <div class="box-item4" style="margin-top:15px;">
                  <div>备注：{{tripInfo.remarks}}</div>
                  <!-- <div>{{tripInfo.remarks}}</div> -->
                  <!-- <div>①全院实行实名制就医。</div>
                  <div>
                      ②发热、发病前28天内有境外史或14天内中高风险地区旅居史、与新冠肺炎病例或无症状感染者有接触史、接触过来自疫情中高风险地区的发热和/或有新冠十大症状的患者，请先到就近医院发热门诊就诊。
                  </div>
                  <div>
                      ③所有人员来院应全程规范佩戴口罩，主动出示健康码+行程码，并配合流行病学调查及测温，非必要不陪诊。
                  </div> -->
              </div>
        </div>
      </div>
      <div style="width:90%;margin-left:5%;font-size:12px;margin-bottom: 10px;">注意：如该路线与您顺路，请自行点击下方期望同行按钮联系对方，暂不支持途经点导航</div>
      <div style="display:flex;width: 90%;margin-left: 5%;justify-content: space-between;">
        <div class="next" style="margin-right:10px;" @click="qwtx" v-if="userInfo.id!=tripInfo.users.id">期望同行</div>
        <div class="next" @click="daohang">导航</div>
        <!-- <div id='bt' class="btmtip">点击去高德地图</div> -->
      </div>
      
    </div>
    <van-popup v-model="showTishi" position="top">
            <div style="width: 100%; text-align: right">
                <img
                    style="width: 55%"
                    src="./comm/tishi.png"
                    alt=""
                    @click="closeTiShi"
                />
            </div>
    </van-popup>
    <van-popup v-model="showmz" position="top">
            <div style="width: 100%; text-align: right">
                <p>免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明免责声明</p>
                <p>免责声明免责声明免责声明免责声明免责声明免责声明</p>
            </div>
    </van-popup>
  </div>
</template>
<script>
import { Icon, Popup,Toast } from "vant";
import AMap from "AMap"; // 引入高德地图
import { passenger } from "@/api";
var driving;
const wx = window.wx;
// var button
export default {
  components: {
    VanIcon: Icon,
    VanPopup: Popup
  },
  data() {
    return {
      map: null,
      autoComplete: null,
      keyword: null,
      searchList: [],
      pmHeight: 500,
      showPopup: false,
      showmz:false,
      showTishi:false,
      pointInfo: null,
      drevingInfo: null,
      tripInfo: null,
      tjdInfo: null,
      userTypes: null,
      userInfo: null
    };
  },
  mounted() {
    // button = document.getElementById('bt');
    this.userInfo = JSON.parse(localStorage.getItem("user"))
    this.userTypes = JSON.parse(localStorage.getItem("user")).types
    this.pmHeight =(
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight) - 209;
      this.getPassengerInfo()
      // this.getwxSign()
  },
  methods: {
    closeTiShi() {
      this.showTishi = false
    },
    fengxiang() {
      this.showTishi = true
      // wx.showMenuItems({
      //   menuList: ["menuItem:share:appMessage"], // 要显示的菜单项，所有 menu 项见附录3
      // });
      setTimeout(() => {
        let urls = encodeURIComponent(`https://ccb.yayale.top/login?resouce=info&id=${this.tripInfo.id}`)
        wx.updateAppMessageShareData({
              title: "SayHi顺风车", // 分享标题
              desc: `车主发布了${this.tripInfo.startAddress}-${this.tripInfo.endAddress}的行程，点击查看`, // 分享描述
              // link: `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf1e471795a1fd4e4&redirect_uri=${urls}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
              link: urls,
              imgUrl: "https://public.yayale.top/6786_20221031163342.png", // 分享图标
              success: function (res) {
                  this.showTishi = false;
                  if (res.err_msg == "shareWechatMessage:ok") {
                      Toast.success("分享成功");
                  }
              },
          });
      }, 1000);           
    },
    getwxSign() {
            let href =  encodeURIComponent(window.location.href.split('#')[0])
            // let signLink = /(Android)/i.test(navigator.userAgent) ? href : window.entryUrl
            let params = {
              url: href,
            }
            passenger.getWxSign(params).then(res => {
              if(res.data.code == 200) {
                wx.config({
                        //beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                        debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: "wxf1e471795a1fd4e4", // 必填，企业微信的corpID
                        timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
                        signature: res.data.data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
                        jsApiList: res.data.data.jsApiList, // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                    });
                    wx.ready(function () {
                        // alert("ready");
                        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
                    });
                    wx.error(function (res) {
                        this.tsRemark = JSON.stringify(res);
                        alert(JSON.stringify(res), "error");
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                    });
              }
            })
    },
    getPassengerInfo() {
            let params = {
              id: this.$route.query.id
            }
            passenger.getPassengerInfo(params).then(res => {
              if(res.data.code == 200) {
                this.tripInfo = res.data.data;
                this.tjdInfo = JSON.parse(res.data.data.tjd);
                this.initMap();
              }
            })
    },
    qwtx() {

      // window.location.href = `tel://${this.tripInfo.users.mobile}`
      this.$router.push({
        name: 'chat',
        query: {
          toId: this.tripInfo.users.id
        }
      })
    },
    daohang() {
      // let _this = this
      window.location.href = `https://uri.amap.com/navigation?from=${this.tripInfo.startLng},${this.tripInfo.startLat},${this.tripInfo.startAddress}&to=${this.tripInfo.endLng},${this.tripInfo.endLat},${this.tripInfo.endAddress}&mode=car&policy=1&src=mypage&coordinate=gaode&callnative=0&platform=mobile`
    },
    formatCarNo(e) {
      const nBegin = e.substring(0,2)
      const nEnd = e.substring(e.length - 2, e.length)
      return nBegin + '***' + nEnd
    },
    time2(data) {
      if (data) {
        console.log(data, 123);
        console.log(this.dayjs().isSame(this.dayjs(data)), 222);
        if (data == this.dayjs().format("YYYY-MM-DD")) {
          return "今天";
        } else if (
          this.dayjs(data) > this.dayjs() &&
          this.dayjs(data) < this.dayjs().add(1, "day")
        ) {
          return "明天";
        } else {
          return this.dayjs(data).format("MM月DD日");
        }
      }
    },
    showList() {
      this.showPopup = true;
    },
    initMap() {
      var _this = this;
      const dom = document.getElementById("container");
      this.map = new AMap.Map(dom, {
        resizeEnable: true,
        zoom: 15,
        center: [120.246232, 29.986425],
        showLabel: true, //不显示地图文字标记, 终于可以配置了TAT
      });
      // // JSAPI 2.0 输入提示插件名称由 AMap.Autocomplete 变更为 AMap.AutoComplete 啦！
      // AMap.plugin('AMap.ToolBar',function(){//异步加载插件
      //     var toolbar = new AMap.ToolBar();
      //     _this.map.addControl(toolbar);
      // });
      if (driving) {
        driving.clear();
      }
      AMap.plugin("AMap.Driving", function () {
        driving = new AMap.Driving({
          // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
          policy: AMap.DrivingPolicy.LEAST_DISTANCE,
          // map 指定将路线规划方案绘制到对应的AMap.Map对象上
          map: _this.map,
          // panel 指定将结构化的路线详情数据显示的对应的DOM上，传入值需是DOM的ID
          // panel: 'container'
        });
        
        var startLngLat = [_this.tripInfo.startLng * 1, _this.tripInfo.startLat * 1];
        var endLngLat = [_this.tripInfo.endLng * 1, _this.tripInfo.endLat * 1];
        let ls = []
        if(_this.tjdInfo!=null) {
          for(let i in _this.tjdInfo) {
            if(_this.tjdInfo[i].location.length == 2) {
                ls.push(new AMap.LngLat(_this.tjdInfo[i].location[0], _this.tjdInfo[i].location[1]))
              } else {
                ls.push(new AMap.LngLat(_this.tjdInfo[i].location.lng, _this.tjdInfo[i].location.lat))
              }
          }
        }
        
        var opts = {
          waypoints: ls
        }

        console.log(startLngLat);
        console.log(endLngLat);
        driving.search(startLngLat, endLngLat,  opts, function (status, result) {
          // 未出错时，result即是对应的路线规划方案
          console.log(status);
          console.log(result,'result');
          // button.onclick = function(){
          //               driving.searchOnAMAP({
          //                   origin:result.origin,
          //                   destination:result.destination
          //               });
          //           }
          _this.drevingInfo = result;
        });
        // _this.showPopup = false
      });
    },
  },
};
</script>
<style  scoped>
/deep/ .van-popup {
  background: none;
}
  .box-item4 {
  /* height: 121px; */
  /* width: 92%;
  margin-left: 4%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border-bottom: 2px solid #f2f2f2; */
  background: rgba(236, 165, 48, 0.16);
  padding: 10px;
  border-radius: 5px;
  color: #eca530;
}
.list-item {
  width: 100%;
  /* margin-left: 4%; */
  background: #fff;
  box-shadow: 0px 2px 10px -4px rgba(2, 66, 58, 0.2);
  border-radius: 8px;
  padding: 15px 15px 10px 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
}
.cklx {
  border: 1px solid #2d83e8;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #2d83e8;
}
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 600px;
}
.search-box1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  background: #fff;
  height: 60px;
  align-items: center;
  z-index: 999;
}
.search-box {
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  display: flex;
  background: #fff;
  min-height: 60px;
  flex-direction: column;
  /* align-items: center; */
  z-index: 999;
}
.search-box-one {
  position: relative;
  width: 90%;
  margin-left: 5%;
  height: 40px;
  border: 2px solid #f2f2f2;
  border-radius: 40px;
  display: flex;
  align-items: center;
}
.input-sty {
  width: 80%;
  margin-left: 2%;
  height: 35px;
  line-height: 35px;
  border: none;
}
.search-icon {
  font-size: 20px;
  margin-left: 10px;
}
.address-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f2;
}
.gdt::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.next {
  width: 100%;
  /* margin-left: 5%; */
  height: 39px;
  background: #2d83e8;
  margin-top: 5px;
  border-radius: 40px;
  text-align: center;
  line-height: 39px;
  color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
}
</style>